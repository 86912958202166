<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        siteId: this.data.siteId,
        moduleCode: this.data.moduleCode,
        moduleName: this.data.moduleName,
        moduleNameEn: this.data.moduleNameEn,
        moduleStyle: this.data.moduleStyle,
        moduleIcron: this.data.moduleIcron,
        moduleDesc: this.data.moduleDesc,
        sortNo: this.data.sortNo,
        moduleType: this.data.moduleType+'',
        useRecommend: this.data.useRecommend+'',
        status: this.data.status.toString(),
        remark: this.data.remark,
        srSignalModuleCalcIdxList:this.data.srSignalModuleCalcIdxList,
        srSignalModuleDisplayIdxList:this.data.srSignalModuleDisplayIdxList
      }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
