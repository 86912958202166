<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="900px" :close="cancel">
    <el-form ref="form" :model="form" size="small" label-width="120px">
      <blockquote class="my-blockquote">模块信息</blockquote>
      <el-row
        :gutter="10"
      >
          <el-col :span="6">
            <el-form-item label="中文名称">
              <el-input v-model="form.moduleName" style="min-width: 100px;" />
            </el-form-item>
          </el-col>
        <el-col :span="6">
          <el-form-item label="英文名称">
            <el-input v-model="form.moduleNameEn" style="min-width: 100px;" />
          </el-form-item>
        </el-col>
          <el-col :span="6">
            <el-form-item label="编码">
              <el-input v-model="form.moduleCode" style="min-width: 100px;" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="排序">
              <el-input v-model="form.sortNo" style="min-width: 100px;" />
            </el-form-item>
          </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
            <el-form-item label="模块类型" prop="moduleType">
              <el-radio v-model="form.moduleType" label=1>交易者</el-radio>
              <el-radio v-model="form.moduleType" label=2>跟随者</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="使用共享信号" prop="useRecommend">
              <el-radio v-model="form.useRecommend" label=1>使用</el-radio>
              <el-radio v-model="form.useRecommend" label=2>不使用</el-radio>
            </el-form-item>
          </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="所属站点">
            <!-- <el-input v-model="form.siteId" hidden style="width: 150px;"/> -->
            <el-select v-model="form.siteId" placeholder="所属站点" style="width: 150px;">
              <el-option
                v-for="item in siteList"
                :key="item.id"
                :label="item.siteName"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
            <el-form-item label="展示样式">
              <el-select v-model="form.moduleStyle" placeholder="展示样式" style="width: 200px;">
                <el-option
                  v-for="item in dispalyList"
                  :key="item.id"
                  :label="item.dispaly_name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        <el-col :span="8">
            <el-form-item label="状态" prop="status">
              <el-radio v-model="form.status" label='1'>有效</el-radio>
              <el-radio v-model="form.status" label='2'>无效</el-radio>
            </el-form-item>
          </el-col>
      </el-row>
        <el-form-item
          v-if="!isSmipleCommunity"
          label="图标">
           <el-row>
            <el-col :span="4">
              <el-upload
                ref="upload"
                class="upload"
                :action="uploadUrl"
                :on-success="handleSuccess"
                multiple
                method:="post"
                :show-file-list="false"
                :file-list="fileLists">
                <el-button size="small" type="primary" plain>选择文件</el-button>
              </el-upload>
            </el-col>
            <el-col :span="4">
              <img v-if="form.moduleIcron!=null" :src="form.moduleIcron" alt="" style="width:50px;height:50px;">
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="简介">
            <el-input type="textarea" autosize v-model="form.moduleDesc" style="width: 700px;"/>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" autosize v-model="form.remark" style="width: 700px;"/>
        </el-form-item>
      <blockquote class="my-blockquote">
        <span>查询参数</span>
        <el-button
              @click.native.prevent="addRow(form.srSignalModuleCalcIdxList,2)"
              type="text"
              size="small">
              增加一行
        </el-button>
      </blockquote>
      <el-row>
          <el-col class="el_col" :span="4">
            指标名称
          </el-col>
          <el-col class="el_col" :span="4">
            比较类型
          </el-col>
          <el-col class="el_col" :span="4">
            比较值
          </el-col>
          <el-col class="el_col" :span="4">
            排序类型
          </el-col>
          <el-col class="el_col" :span="4">
            排序号
          </el-col>
          <el-col class="el_col" :span="4">
            操作
          </el-col>
      </el-row>
      <el-row v-for="(item,index) of form.srSignalModuleCalcIdxList" :key="index">
          <el-col class="el_col" :span="4">
            <el-select v-model="item.indexId" placeholder="指标名称">
                <el-option
                  v-for="item in indexList"
                  :key="item.id"
                  :label="item.indexName"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-col>
          <el-col class="el_col" :span="4">
            <el-select v-model="item.compareType" placeholder="比较类型">
                <el-option
                  v-for="item in compareTypeList"
                  :key="item.id"
                  :label="item.dispaly_name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-col>
          <el-col class="el_col" :span="4">
            <el-input v-model="item.compareValue" />
          </el-col>
          <el-col class="el_col" :span="4">
            <el-select v-model="item.sortType" placeholder="排序类型" >
                <el-option
                  v-for="item in sortTypeList"
                  :key="item.id"
                  :label="item.dispaly_name"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-col>
          <el-col class="el_col" :span="4">
            <el-input v-model="item.sortNo" />
          </el-col>
          <el-col class="el_col" :span="4">
             <el-button
              @click.native.prevent="deleteRow(index, form.srSignalModuleCalcIdxList)"
              type="text"
              size="small">
              移除
            </el-button>
          </el-col>
      </el-row>
      <blockquote class="my-blockquote">
        <span>显示指标</span>
        <el-button
              @click.native.prevent="addRow(form.srSignalModuleDisplayIdxList,1)"
              type="text"
              size="small">
              增加一行
        </el-button>
      </blockquote>
      <el-row>
          <el-col class="el_col" :span="8">
            指标名称
          </el-col>
          <el-col class="el_col" :span="8">
            排序号
          </el-col>
          <el-col class="el_col" :span="8">
            操作
          </el-col>
      </el-row>
      <el-row v-for="(item,index1) of form.srSignalModuleDisplayIdxList" :key="index1+'1'">
          <el-col class="el_col" :span="8">
            <el-select v-model="item.indexId" placeholder="指标名称">
                <el-option
                  v-for="item in indexList"
                  :key="item.id"
                  :label="item.indexName"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-col>
          <el-col class="el_col" :span="8">
            <el-input v-model="item.sortNo" />
          </el-col>
          <el-col class="el_col" :span="8">
             <el-button
              @click.native.prevent="deleteRow(index1, form.srSignalModuleDisplayIdxList)"
              type="text"
              size="small">
              移除
            </el-button>
          </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit,save } from '@/api/community/srSignalModuleList'
import { initData } from '@/api/data'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
  created() {
    this.initSiteList()
    this.initIndexList()
  },
  data() {
    return {
      loading: false, dialog: false,rules:[],
      siteList:[],fileLists:[],indexList:[],
      compareTypeList:[
        {id:'1',dispaly_name:'='},
        {id:'2',dispaly_name:'!='},
        {id:'3',dispaly_name:'>'},
        {id:'4',dispaly_name:'<'},
        {id:'5',dispaly_name:'>='},
        {id:'6',dispaly_name:'=<'}
      ],
      sortTypeList:[
        {id:'ASC',dispaly_name:'升序'},
        {id:'DESC',dispaly_name:'降序'}
      ],
      form: {
        id: '',
        siteId:1,
        moduleCode: '',
        moduleName: '',
        moduleNameEn: '',
        moduleStyle: 1,
        moduleIcron: '',
        moduleDesc: '',
        moduleType:'1',
        useRecommend:'1',
        sortNo: '',
        status: '1',
        remark: '',
        srSignalModuleCalcIdxList:[],
        srSignalModuleDisplayIdxList:[]
      },
      uploadUrl: process.env.VUE_APP_API+'community/auth/file/upload',
    }
  },
  computed:{
    isSmipleCommunity(){
      let flag = false;
      for (let i = 0; i < this.siteList.length; i++) {
        if(this.siteList[i].id == this.form.siteId){
          if(this.siteList[i].siteType == 3){
            flag = true;
            break;
          }
        }
      }
      return flag;
    },
    dispalyList(){
      let styleList = [
        {id:9,dispaly_name:'N宫格'},
        {id:1,dispaly_name:'四宫格'},
        {id:2,dispaly_name:'四宫格(1+4)'},
        {id:3,dispaly_name:'三宫格(1+3)'},
        {id:4,dispaly_name:'六宫格(1+6)'}
      ];
      if(this.isSmipleCommunity){
        styleList = [
          {id:1,dispaly_name:'四宫格'},
        ];
        this.form.moduleStyle = 1;
      }
      return styleList;
    },
  },
  methods: {
    addRow(rows,type) {
      if(type==1) {
        let num = rows.length
        if(this.form.moduleStyle==1){
          if(num==4){
            this.$notify({
              title: '最多可展示四个指标',
              type: 'error',
              duration: 2500
            })
            return
          }
        }else if(this.form.moduleStyle==2){
          if(num==3){
            this.$notify({
              title: '最多可展示三个指标',
              type: 'error',
              duration: 2500
            })
            return
          }
        }else if(this.form.moduleStyle==3){
          if(num==3){
             this.$notify({
              title: '最多可展示三个指标',
              type: 'error',
              duration: 2500
            })
            return
          }
        }else if(this.form.moduleStyle==4){
          if(num==2){
              this.$notify({
              title: '最多可展示两个指标',
              type: 'error',
              duration: 2500
            })
            return
          }
        }
        rows.push(
          {indexId:1,
          sortNo:1
          });
      }else {
        rows.push(
          {indexId:1,
          compareType:'1',
          compareValue:0,
          sortType:'ASC',
          sortNo:1
          });
      }

    },
    deleteRow(index, rows) {
        rows.splice(index, 1);
    },
    cancel() {
      this.resetForm()
    },
    initSiteList(){
      const url = 'cms/crm/srSite'
      const sort = 'sortNo,asc'
      const params = {sort: sort,page:0,size:1000}
      initData(url, params).then(res => {
          this.siteList = res.content
          this.form.siteId =this.siteList[0].id
        }).catch(err => {
          reject(err)
        })
    },
    initIndexList(){
      const url = 'community/crm/srSignalIndexList'
      const params = {}
      initData(url, params).then(res => {
          this.indexList = res.content
        }).catch(err => {
          reject(err)
        })
    },
    handleSuccess(response){
        //上传成功要处理的事
        this.form.moduleIcron=response.data.content[0].url;
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      save(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      save(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.fileLists=[]
      this.form = {
        id: '',
        siteId: null,
        moduleCode: '',
        moduleName: '',
        moduleNameEn: '',
        moduleStyle: 1,
        moduleIcron: '',
        moduleDesc: '',
        moduleType:'1',
        useRecommend:'1',
        moduleSql: '',
        sortNo: '',
        status: '1',
        remark: '',
        srSignalModuleCalcIdxList:[],
        srSignalModuleDisplayIdxList:[]
      }
    }
  }
}
</script>

<style scoped>
.el_col{
    line-height: 50px;
    /* border: solid 1px; */
    text-align: center;
    height: 50px;
    align-content: center;
    padding-left: 5px;
    margin-bottom: 5px;
}

</style>
